<template>
    <div class="page-warp container-page">
        <div class="scroll-box">
            <div class="row message-item"
                @click="toDetail(item)"
                v-for="(item,inx) in loopList" :key="inx">
                <div class="message-sign">
                    {{item.noticeType == 1 ? '通知':'公告'}}
                    <span class="d">{{filterDate(item.createTime)}}</span>
                </div>
                <div class="row message-title webkit-box-line-clamp-orient">{{item.noticeTitle}}</div>
            </div>
            <infinite-loading 
                v-if="!finished" 
                @infinite="onLoad"
                ref="infiniteLoading">
            </infinite-loading>
            <div v-else class="row no-datas">没有更多了</div>
        </div>
    </div>
</template>
<script>
import { pageQuery } from "@/api/message"
import InfiniteLoading from "vue-infinite-loading";
export default {
    name:'Message',
    components: {
        InfiniteLoading
    },
    data() {
        return {
            activeNames: [],
            loading: false,
            finished: false,
            page:1,
            pageSize:10,
            loopList:[],
            noticeContent:null
        };
    },
    created() {
    },
    methods: {
        filterDate(row){
            let asDa = row.split(' ');

            return asDa[0].substring(5)
        },
        async onLoad($state) {
            // 异步更新数据
            let pramas={
                pageNum:this.page,
                pageSize:this.pageSize
            }
            this.loading = true;
            await pageQuery (pramas).then(res=>{
                this.loopList = this.loopList.concat(res.rows);
                if (res.total <= this.loopList.length) {
                    this.finished = true;
                }
                this.page +=1;
                this.loading = false;
                
                if(this.$refs.infiniteLoading) this.$refs.infiniteLoading.$emit('$InfiniteLoading:loaded'); //完成加载
            })
        },
        toDetail(row){
            this.$router.push({path:'/messageDetail',query:{id:row.noticeId}})
        }
    },
};
</script>
<style lang="less" scope>
.message-item {
    background:url('../../assets/images/icon/tz-icon@2x.png');
    background-repeat: no-repeat;
    background-position: 10px center;
    background-size: 18px auto;
    box-sizing: border-box;
    padding:10px 10px 10px 38px;
    border-bottom:1px solid #eee;
    .message-sign {
        font-size:14px; line-height: 20px;
        .d {
            opacity: 0.4; float: right; font-size: 12px;
        }
    }
    .message-title { 
        font-size: 12px; line-height: 30px;
        box-sizing: border-box; padding-right:20px;
    }
}
</style>