/*
 * @Description: 通知
 */

import {get } from "@/utils/request"

export function pageQuery(data) {
    return get('/system/notice/list', data)
}
export function findById(data) {
    return get('/system/notice/' + data)
}